import useOrders from '@/api/hooks/useOrder'
import TextSearch from '@/components/custom/search/TextSearch'
import NetworkWrapper from '@/components/global/NetworkWrapper'
import { page_view } from '@/lib/events'
import { DatePickerWithRange } from '@/pages/dashboard/case history/component/DatePickerWithRange'
import NoCaseFound from '@/pages/dashboard/case history/component/NoCaseFound'
import SearchResult from '@/pages/dashboard/case history/component/SearchResult'
import { SelectSource } from '@/pages/dashboard/case history/component/SelectSource'

import { useEffect } from 'react'

export default function CaseHistory() {
  const { getCaseHistory, caseHistory, ...rest } = useOrders()

  useEffect(() => {
    if (rest?.status?.fetchCaseHistory !== 'loading') {
      getCaseHistory()
      page_view({name: 'case history'})
    }
  }, [])

  return (
    <NetworkWrapper storeKey={'fetchCaseHistory'} {...rest}>
      <div className='flex w-full  flex-col items-center justify-center pt-10'>
        <div className='w-full max-w-5xl'>
          <div className=' flex w-full flex-col   items-center justify-between gap-y-4 p-4 sm:flex-row sm:gap-y-0 sm:px-0'>
            {/* <SearchBox /> */}
            <TextSearch />
            <div className='flex items-center justify-center gap-4'>
              <SelectSource />
              <DatePickerWithRange />
            </div>
          </div>
          {!!caseHistory && caseHistory?.length > 0 && (
            <div className=''>
              <SearchResult caseHistory={caseHistory} />
            </div>
          )}
          {!!caseHistory && caseHistory?.length === 0 && (
            <div className='w-5xl  h-[50vh] '>
              <NoCaseFound />
            </div>
          )}
        </div>
      </div>
    </NetworkWrapper>
  )
}
