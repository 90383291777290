import React from 'react'

interface INetworkWrapper {
  children: React.ReactNode
  storeKey: any
  status: any
  errors: any
}

export const PageLoading = () => {
  return (
    <div className='flex h-screen items-center justify-center'>
      <div className='h-16 w-16 animate-spin rounded-full border-4 border-dotted border-purple-500'></div>
    </div>
  )
}

const NetworkWrapper: React.FC<INetworkWrapper> = ({
  storeKey,
  status,
  errors,
  children,
}) => {
  if (status[storeKey] === 'loading') {
    return <PageLoading />
  }

  if (status[storeKey] === 'failed') {
    return <div>Error: {errors[storeKey]}</div>
  }

  if (status[storeKey] === 'succeeded' || status[storeKey] === 'idle') {
    return <div>{children}</div>
  }

  return <div className='h-screen w-screen flex justify-center items-center'>
    <img src='/images/favicon.png' className='w-52 h-52 pulse' />
  </div>
}

export default NetworkWrapper
