import useOrders from '@/api/hooks/useOrder'
import { Input } from '@/components/ui/input'
import { useAppDispatch } from '@/store/redux/hooks'
import { Icon } from '@iconify/react/dist/iconify.js'
import React from 'react'

type Props = {}

export default function TextSearch({}: Props) {
  const { updateSearchKeywordDebounce, caseHistoryFilter } = useOrders()
  const [searchWord, setSearchWord] = React.useState('')

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value)
    setSearchWord(e.target.value)
    updateSearchKeywordDebounce(e.target.value)
  }

  const clearSearch = () => {
    setSearchWord('')
    updateSearchKeywordDebounce('')
  }

  return (
    <div className='relative w-full sm:max-w-[400px]'>
      <Icon
        icon={'solar:magnifer-line-duotone'}
        className='absolute left-3 top-1.5  mr-2 text-xl text-gray-800'
      />
      <Input
        value={searchWord}
        onChange={handleSearch}
        type='text'
        className='w-full pl-10'
        placeholder='Case Id, patient name, phone number'
      />
      {searchWord?.length > 0 && (
        <Icon
          icon={'solar:close-circle-line-duotone'}
          className='absolute right-0 top-2 mr-2  text-xl text-gray-800 hover:cursor-pointer'
          onClick={clearSearch}
        />
      )}
    </div>
  )
}
