import { useLocation, useNavigate } from 'react-router-dom'

function useQueryParams() {
  const location = useLocation()
  const navigate = useNavigate()

  const updateQueryParams = (params) => {
    const searchParams = new URLSearchParams(location.search)

    Object.keys(params).forEach((key) => {
      if (params[key] !== undefined && params[key] !== null) {
        searchParams.set(key, params[key])
      }
    })

    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    })
  }

  const removeQueryParams = (keys) => {
    const searchParams = new URLSearchParams(location.search)

    keys.forEach((key) => {
      searchParams.delete(key)
    })

    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    }, {
      replace: true
    })
  }

  const replacePathQuery = (obj) => {
    const searchParams = new URLSearchParams('')
    Object.keys(obj).forEach((key) => {
      searchParams.set(key, obj[key])
    })
    navigate({
      pathname: location.pathname,
      search: searchParams.toString()
    }, {
      replace: true
    })
  }

  const injectQueryParams = (params) => {
    const searchParams = new URLSearchParams(location.search)

    Object.keys(params).forEach((key) => {
      if (!searchParams.has(key)) {
        searchParams.append(key, params[key])
      }
    })

    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    })
  }

  const getQueryParam = (key) => {
    const searchParams = new URLSearchParams(location.search)
    return searchParams.get(key)
  }

  return {
    updateQueryParams,
    removeQueryParams,
    injectQueryParams,
    getQueryParam,
    replacePathQuery
  }
}

// Usage example in a React component:

// const { updateQueryParams, removeQueryParams, injectQueryParams } = useQueryParams();

// updateQueryParams({ key1: 'value1', key2: 'value2' });
// removeQueryParams(['key1', 'key2']);
// injectQueryParams({ key3: 'value3' });

export default useQueryParams
